import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: colors.blueGrey,
        secondary: colors.teal,
        accent: colors.blue.darken4
      }
    },
    icons: {
      iconfont: 'fa', //'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' ||  || 'fa4' || 'faSvg'
    },
  }
});
