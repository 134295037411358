<template>
  <div class="about">
    <v-container class="">
      <v-row class="" align="center" justify="center">
        <v-col cols="12" md="12" xl="9" align-self="center">
          <div>
            <v-img src="../images/KreuzUndSchrift.png" contain max-height="350" class="grey darken-1"></v-img>
            <br>
            <h1 class="my-1 haeusle blue--text text--darken-4 d-inline">Wir</h1>
            <span class="ml-2 text-h6"> sind nicht nur jung und frisch</span>
            <br class="my-0">
            <h1 class="my-1 haeusle blue--text text--darken-4 d-inline">Sondern</h1>
            <span class="ml-2 text-h6">auch schon ganz schön alt und weise</span>
            <br>
            <br>
            <span class="text-h5">Seit fast 90 Jahren gibt es die Liebenzeller Gemeinschaft in Haiterbach.</span>
            <br>
            <span class="text-h5">Am Anfang stand eine Evangelisation und der Wunsch, eine Liebenzeller Gemeinschaft in Haiterbach zu gründen. Man traf sich zunächst in Wohnstuben und kleinen Versammlungsräumen. Aber schon zu dieser Zeit kamen zum Predigtdienst zur "Stund" am Sonntag Abend, Prediger die am Liebenzeller Missionsseminar ausgebildet worden waren oder in der Ausbildung standen oder Missionsschwestern.</span>
            <br>
            <br>
            <span class="text-h5">Im Jahr 1954 wurde der EC-Jugendbund als Teil der Liebenzeller Gemeinschaft gegründet. Der Beginn einer ausgeprägten Jugendarbeit die bis heute einen zentralen Teil unserer Arbeit ausmacht</span>
            <br>
            <br>
            <span class="text-h5">1959 sollte dann ein rechtlicher Rahmen um die Gemeinschaft gespannt werden. Der Verein "Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach" wurde gegründet. Die Vereinsstruktur ist bis heute ein sehr wichtiger organisatorischer Teil unserer Idendität.</span>
            <br>
            <br>
            <span class="text-h5">Immer wieder gab es Probleme mit der räumlichen Situation, und immer wieder Pläne ein eigenes Gemeinschaftshaus zu bauen. Aber erst nach 30 Jahren - 1962 - war es endlich soweit. Das warten auf Gottes Wege hatte sich gelohnt. Man konnte ein eigenes Haus im Gebiet der "Heiligen Brunnen" bauen.</span>
            <br>
            <v-row align-content="center" justify="space-around">
              <v-col cols="11" md="7" lg="4">
                <span class="text-h5">Das</span>
                <span class="my-4 text-md-h3 text-h4 blue--text text--darken-4 " id="haeusle"> häusle</span>
                <span class="ml-4 text-h5">war geboren</span>
              </v-col>
            </v-row>
            <v-img src="../images/hausAlt.png" contain max-height="350" class="transparent"></v-img>
            <br>
            <br>
            <span class="text-h5">Das Häusle wurde 2004 dann zu einem Haus, das regelmäßig mit leben gefüllt ist. Wir freuen uns, immer wieder neue Konzepte und Ideen auszuprobieren und danken unserem Herrn für sein Wirken in unserer Mitte. </span>
            <br>
            <span class="text-h5">ER war, ist und bleibt immer derselbe und gibt uns das Fundament, um seine Liebe auch in Zukunft auf verschiedenste Art und Weise weiter zu sagen.</span>
            <br>
            <br>
            <span class="text-h5">Das war aber noch lange nicht alles, was es über uns zu sagen gibt. Wer mehr erfahren möchte, sollte am besten im Häusle direkt vorbei und mit uns ins Gespräch kommen.</span>
            <br>
            <br>
            <span class="text-h5">Wir freuen uns auf Dich!</span>
            <br>
            <br>
            <!-- <span class="text-h6">Stellvertretend hier unsere Gemeinschaftsleiter: </span> -->
            <!-- <v-img src="../images/"> -->

            <!-- </v-img> -->
            <br>
            <br>
            <br>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'History',
  mounted: function () {
    // document.title = "LGV Haiterbach - das Häusle";
  }
}
</script>

<style>
  #haeusle {
    font-family: 'Playball', 'sans-serif' !important;
  }
  .haeusle {
    font-family: 'Playball';
  }
</style>