<template>
  <div class="white" >
    <v-container class="grey lighten-3">
      <br>
      <v-row vertical-align="center">
        <v-col cols="auto">
          <h2 class="d-inline">Osterausstellung im </h2>
          <h1 class="my-1 haeusle blue--text text--darken-4 d-inline"> Häusle</h1>
        </v-col>
        <v-col cols="auto">
          <h1 class="">Ostern ERlebt</h1>
        </v-col>
        <v-col cols="auto">
          <h1 class="my-1 haeusle blue--text text--darken-4">27. März - 7. April 2023</h1>
        </v-col>
      </v-row>
      <v-row vertical-align="center">
        <v-col cols="auto">
          <h2>Zwei sehr gesegnete Wochen liegen hinter uns und wir sind Gott sehr dankbar für diese Zeit!</h2>
          <h2>In kürze wird es hier eine Rückblick geben</h2>
        </v-col>

      </v-row>
      <br>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "osterausstellung",
  components:{
  },
  mounted: function(){
    var xhr = new XMLHttpRequest();
    var home = this;
    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        home.setOster(xhr.responseText);
      }
    };
    xhr.open("POST", '/php/ServerOperations.php', true);
    xhr.send("getoster");
  },
  data (){
    return {
      oster: Array,
      showW: false,
      showM: false,
      showC: false,

    imagePaths: [
        '../../bildchen/OsterausstellungFlyer1.jpg',
      ],
    }
  },
  methods:{
    setOster: function(jsonArray){
      var picNames = JSON.parse(jsonArray);
      var newOsterPics = new Array();
      for(var i = 0; i < picNames.length; i++){
        newOsterPics.push("../bildchen/" + picNames[i]);
      }
      this.oster = newOsterPics;
    },
}}
</script>

<style>
li{
  font-weight: bold;
}
.anmeldenButtonOstern{
  text-align: center;
  margin: auto;
  display: flex;
  /* border-style: solid;
  border-color: blue; */
  padding: 10px;
}
.flyerErLebt{
  width: 60%;
  text-align: center;
  margin: auto;
  display: flex;
}
.leftStart{
  left: -100%;
  transition: left 0.5s;
}
.rightStart{
  left: 100%;
  transition: left 0.5s;
}

.slide{
  left: 0%
}
</style>