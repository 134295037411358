<template>
  <v-card flat rounded="0" :height="innerHeight" color="transparent"
    >
      <transition name="fade" class="fade">
        <v-img name="firstimg"
          :height="innerHeight"
          :src="pictureName"
          v-if="showFirst"
          key="first"
        />  
        <v-img name="secondimg"
          :height="innerHeight"
          :src="pictureName"
          v-else
          key="second"
             
        />  
    </transition>
  </v-card>
</template>

<script>
  export default {
    name: 'carousel',
    props: {
      interval: String,
      elements: Array,
      height: Number
    },
    data (){
      return {
        pictureIndex: 0,
        innerInterval: 1000,
        showFirst: true
      }
    },
    mounted: function () {
      if (this.interval == undefined){
        this.innerInterval = 5000;
      }
      else if (this.interval < 500){
        this.innerInterval = 500;
      }
      else if (this.interval > 10000){
        this.innerInterval = 10000;
      }
      else{
        this.innerInterval = this.interval;
      }
      
      window.setInterval(() => { this.move() }, this.interval);
    },
    methods:{
      move: function(){
        if (this.elements != undefined && this.elements.length > 1 ){
          var newPicIndex = this.pictureIndex;
          newPicIndex++;

          // den Index auf null setzen, wenn das ende des Arrays erreicht wurde
          if (newPicIndex >= this.elements.length){
            newPicIndex = 0;
          }
          this.pictureIndex = newPicIndex;
          this.showFirst = ! this.showFirst;
        }
      }
    },
    computed:{
      pictureName: function(){
        return this.elements[this.pictureIndex];
      },
      innerHeight: function(){
        if (this.height == undefined){
          return 450;
        }
        if(this.height < 50){
          return 50;
        }
        if (this.height > 1000){
          return 1000;
        }
        return this.height;
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: all 1.5s linear;
  }
  .fade-enter, .fade-leave-to{
    position: absolute;
    width: 100%;
    opacity: 0;
  }
  .fade-leave, .fade-enter-to{
    position: absolute;
    width: 100%;
    opacity: 1;
  }
</style>