<template>
  <nav>
    <v-app-bar 
      app 
      color="transparent"
      clipped-left
      >
      <v-overlay color="primary lighten-3" z-index="-1" opacity="0.9"/>
      <v-row class="ml-1">
        <v-col v-if="smanddown" cols="11" class="ml-0 pl-0" align-self="end">
          <v-layout class="ml-0" justify-start align-center>
            <a target="_blank" href="https://www.lgv.org/">
              <v-img max-width="40" src="../images/LgvLogo_logoOnly.png"></v-img>
            </a>
            <router-link to="/home/a">
              <v-img class="ml-2 mt-2 px-2" max-width="250" src="../images/LogoText_Lang.png"></v-img>
            </router-link>
            <a v-if="smOnly" target="_blank" href="https://www.liebenzell.org/" class="ml-5 mr-2">
              <v-img max-width="40" src="../images/LM_logo.png" ></v-img>
            </a>
            <a v-if="smOnly" target="_blank" href="https://www.swdec.de/" class="ml-2">
              <v-img max-width="40" src="../images/EC_Logo.png"></v-img>
            </a>
          </v-layout>
        </v-col>
        <v-col v-if="!smanddown" cols="11" align-self="end">
          <v-layout justify-center align-center>
            <a target="_blank" href="https://www.lgv.org/" >
              <v-img max-width="40" src="../images/LgvLogo_logoOnly.png"></v-img>
            </a>
            <router-link to="/home/a">
              <v-img max-width="250" height="28" class="ml-2 mr-4 mt-2" src="../images/LogoText_Lang2.png"></v-img>
            </router-link>
            <v-menu
              v-for="item in navigationitems" :key="item.text"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn  link :to="item.subitems ? '' : item.link"
                  depressed
                  color="transparent"
                  class="mx-0 mx-md-2"
                  v-bind="attrs"
                  v-on="on"
                >
                    {{item.text}}
                </v-btn>              
               </template>
               <v-list v-if="item.subitems && item.subitems.length > 0">
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                  link
                  :to="subitem.link.path + '/' + subitem.link.hash"
                  @click.native="checkScroll(subitem.link.hash)"
                >
                  <v-list-item-title>{{ subitem.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <a target="_blank" href="https://www.liebenzell.org/" class="mr-2">
              <v-img max-width="40" src="../images/LM_logo.png" ></v-img>
            </a>
            <a target="_blank" href="https://www.swdec.de/" class="ml-2">
              <v-img max-width="40" src="../images/EC_Logo.png"></v-img>
            </a>
          </v-layout>
        </v-col>
        <v-col cols="1" align-self="center">
          <v-layout justify-end >       
            <v-btn icon class="hidden-md-and-up" @click="drawer = !drawer">
              <v-icon v-if="!drawer">mdi-menu</v-icon>
              <v-icon v-if="drawer">menu_open</v-icon>
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer app color="transparent" 
      v-bind:permanent="false" 
      clipped
      :right="true"
      :expand-on-hover="false" 
      v-model="drawer"
      v-if="showdrawer">
      <v-overlay color="primary lighten-3" opacity="0.95" z-index="-1">
      </v-overlay>
      <v-list>
        <v-subheader>
          <v-spacer></v-spacer>
          <v-btn icon class="hidden-md-and-up" @click="drawer = !drawer">
            <v-icon>menu_open</v-icon>
          </v-btn> 
        </v-subheader>
        <v-divider></v-divider>
        <v-list-item v-for="item in navigationitems" 
          :key="item.text" 
          link 
          :to="item.subitems ? '' : item.link"
          @click="drawer = false">
          <v-list-item-action>
            <v-icon >{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title 
                  v-bind="attrs"
                  v-on="on"
                >{{item.text}}</v-list-item-title>         
               </template>
               <v-list v-if="item.subitems && item.subitems.length > 0">
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                  link
                  :to="subitem.link.path + '/' + subitem.link.hash"
                  @click.native="checkScroll(subitem.link.hash)"
                >
                  <v-list-item-title>{{ subitem.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
          </v-list-item-content>
        </v-list-item>  
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
  export default{
    props:{
      navigationitems: Array
    },
    data() {
      return {
        drawer: false,
        showSubItems: ""
      }
    },
    computed: {
      smanddown: function() {
        if (this.$vuetify.breakpoint.smAndDown){
          return true;
        }
        return false;
      },
      smOnly: function() {
        if (this.$vuetify.breakpoint.smOnly){
          return true;
        }
        return false;
      },
      showdrawer: function() {
        return !this.$vuetify.breakpoint.mdAndUp && this.drawer;
      },
    },
    methods:{      
      checkScroll(hash){
        if(hash){
          this.drawer = false;
          var element = document.getElementById(hash);
          var headerOffset = 80;
          if (element){
            var elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            var offsetPosition = elementPosition - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
          }
        }
      }
    }
  }
</script>