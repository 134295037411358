<template>
  <div class="transparent">
    <Carousel interval=8000 :height="carouselHeight" :elements="mainCar" />
    <v-container class="transparent" >
      <v-layout justify-center align-center >
        <span class="my-4 mx-0 text-h5 text-sm-h4 text-md-h3">Willkommen im </span>
        <span class="my-4 ml-4 text-h3 text-sm-h3 text-md-h2 accent--text " id="haeusle"> häusle</span>
      </v-layout>

      <v-row justify="center" class="mb-4">
        <v-col cols="11" lg="9">
          <v-row class="my-22 grey lighten-3" align="center">
            <v-col cols="6" md="3" class="mt-4 mb-0 py-0">
              <h1 class="mb-0 haeusle accent--text d-inline">Glauben</h1>
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <span class="text-h5">an einen ewigen Gott, der mit unserem Leben etwas zu tun haben will und uns seine geliebte Kinder nennt</span>
            </v-col>
          </v-row>
          <v-row class="my-2 grey lighten-3" align="center">
            <br>
            <v-col cols="6" md="3" class="py-0">
              <h1 id="font" class="haeusle accent--text d-inline">Gemeinschaft</h1>
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <span class="text-h5">wollen wir mit IHM und mit anderen Christen haben, uns über unseren Glauben austauschen</span>
            </v-col>
            <br>
          </v-row>
          <v-row class="my-2 grey lighten-3" align="center">
            <v-col cols="6" md="3" class="py-0">
              <h1 id="font" class="haeusle accent--text d-inline">Leben</h1>
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <span class="text-h5">in seiner Nachfolge - und die Welt verändern (lassen)</span>
            </v-col>
            <p></p>
          </v-row>
        </v-col>
      </v-row>
      
      <v-row id="impuls" v-if="news && news.message" justify="center">
        <v-col cols="12" xl="9">
          <v-card  color="primary lighten-1">
            <h1 class="ml-5 white--text" >Information</h1>
            <v-row justify="center">
              <v-col  cols="12" xl="9">
                <v-card class="pa-4 ma-5" >
                  <h1 class="haeusle accent--text">
                    {{news.message.title}}
                  </h1>
                  <br>
                  <div v-for="(text, index) in news.splittext" :key="index" >
                    <span class="text-h5" style="text-align: justify;">{{text}}</span>
                    <br>
                  </div>
                  
                  <!-- LINK -->
                  <div v-if="news.message.link && news.message.link!=''">
                    <a v-if="news.message.link.substring(0,1) != '/'" :href="news.message.link" target="_blank" class="text-decoration-none">
                      <h1 v-if="news.message.linktext" class="mb-0 ml-3 haeusle accent--text text-decoration-none">
                        <v-icon v-if="news.message.linkicon" class="accent--text mb-2" x-large>{{news.message.linkicon}}</v-icon>
                        <v-icon v-else class="accent--text mb-2" x-large>mdi-open-in-new</v-icon>
                        <span class="ml-2">{{news.message.linktext}}</span>
                      </h1>
                      <h1 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
                        <v-icon v-if="news.message.linkicon" class="accent--text mb-2" x-large>{{news.message.linkicon}}</v-icon>
                        <v-icon v-else class="accent--text mb-2" x-large>mdi-open-in-new</v-icon>
                        <span class="ml-2">{{news.message.link}}</span>
                      </h1>
                    </a>
                    <router-link v-if="news.message.link.substring(0,1) == '/'" :to="news.message.link" class="text-decoration-none">
                      <h1 v-if="news.message.linktext" class="mb-0 ml-3 haeusle accent--text">
                        <v-icon v-if="news.message.linkicon" class="accent--text mb-2" x-large>{{news.message.linkicon}}</v-icon>
                        <v-icon v-else class="accent--text mb-2" x-large>mdi-open-in-new</v-icon>
                        <span class="ml-2">{{news.message.linktext}}</span>
                      </h1>
                      <h1 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
                        <v-icon v-if="news.message.linkicon" class="accent--text mb-2" x-large>{{news.message.linkicon}}</v-icon>
                        <v-icon v-else class="accent--text mb-2" x-large>mdi-open-in-new</v-icon>
                        <span class="ml-2">{{news.message.link}}</span>
                      </h1>
                    </router-link>
                  </div>
                  <!-- LINK -->
                  
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- IMPULS -->
      <v-row id="impuls" v-if="impuls && impuls.message" justify="center">
          <v-col cols="12" xl="9">
            <v-card color="">
              <h1 class="ml-5">Impuls</h1>
                <v-col cols="12" class="transparent">
                  <v-card color="primary lighten-5" min-height="150" class="pa-2">
                    <v-img style="float: left" v-if="impuls.pictures[0]!=''" class="d-inline-block mr-3" width="150" contain :src="impuls.pictures[0]"></v-img>
                    <h3 v-if="impuls.message.title" class="pb-0">{{impuls.message.title}}</h3>
                    <div v-for="text in impulstexts" :key="text" >
                      <span style="text-align: justify;">{{text}}</span>
                      <br>
                    </div>
                  </v-card>
                </v-col>
            </v-card>
          </v-col>
        </v-row>

      <!-- AKTUELLES -->
        <v-row id="aktuell" justify="center">
          <v-col cols="12" xl="9">
            <v-card color="">
              <h1 class="ml-5">Aktuelles</h1>
                <v-col cols="12">
                  <v-row flat v-for="element in actComponents"
                        :key="element.message.title"
                      >
                      <v-col cols="12">
                        <news-element 
                          :newsElement="element"
                          class="pa-2 mb-3"
                          >
                        </news-element>
                      </v-col>
                  </v-row>
                </v-col>                
            </v-card>
          </v-col>
        </v-row>

      
      <v-card height="300" color="transparent" rounded="0" flat></v-card>
    </v-container>    
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '../components/Carousel';
import NewsElement from '../components/NewsElement';

export default {
  name: 'Home',
  components: {
    Carousel,
    NewsElement
  },
  mounted: function () {
    // document.title ="Liebenzeller Gemeinschaft Haiterbach - das Häusle"
    var xhr = new XMLHttpRequest();
    var home = this;
    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        home.setMainCar(xhr.responseText);
        // gleich noch die aktuellen Nachrichten holen:
        xhr.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            home.setActComponents(xhr.responseText);
          }
        };
        xhr.open("POST", '/php/ServerOperations.php', true);
        xhr.send("getaktuell");
      }
    };
    xhr.open("POST", '/php/ServerOperations.php', true);
    // xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // xhr.send("getmaincar");
    xhr.send("getmaincar");
    
  },
  data() {
    return{
      mainCar: Array,
      actComponents: Array,
      impuls: Object,
      news: Object
    }
  },
  computed: {
    carouselHeight: function(){
      if (this.$vuetify.breakpoint.lgAndUp){
        return 450;
      }
      if (this.$vuetify.breakpoint.smAndUp){
        return 350;
      }
      return 290;
    },
    impulstexts: function(){
      return this.impuls.message.content.split("\r\n");
    }
  },
  methods:{
    setMainCar: function(jsonArray){
      var picNames = JSON.parse(jsonArray);
      var newMainCarPics = new Array();
      for(var i = 0; i < picNames.length; i++){
        newMainCarPics.push("../bildchen/" + picNames[i]);
      }
      this.mainCar = newMainCarPics;
    },
    setActComponents: function(jsonObject){
      if (jsonObject.length > 5){
        var jsonElements = JSON.parse(jsonObject);
        var newsElements = new Array();
        Object.keys(jsonElements).forEach(key => {
          if (key == "impuls"){
            this.impuls = jsonElements[key];
          }
          else if(key == "news"){
            this.news = jsonElements[key];
            if (this.news.message.content != undefined){
              this.news.splittext = this.news.message.content.split("\r\n");
            }
          }
          else{
            if (jsonElements[key].message){
              jsonElements[key].message.date = new Date(jsonElements[key].message.date);
              if (jsonElements[key].message.enddate != undefined){
                jsonElements[key].message.enddate = new Date(jsonElements[key].message.enddate);
              }
              newsElements.push(jsonElements[key]);
            }
          }
        })
        newsElements.sort (function(a,b) {
          if (!a.message.date == undefined && b.message.date == undefined){
            return 0;
          }
          if (!a.message.date == undefined){
            return 0;
          }
          if (!b.message.date == undefined){
            return 1;
          }
          if (b.message.date < new Date()){
            return 1;
          }
          if (a.message.date > b.message.date){
            return 1;
          }
          if (a.message.date < b.message.date){
            return -1;
          }
          return 0;
        });
        this.actComponents = newsElements;
      }
    }
  },
  updated: function(){      
    if(this.$route.params.hash){
      var element = document.getElementById(this.$route.params.hash);
      var headerOffset = 60;
      if (element){
        var elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
      }
    }
  },
}
</script>

<style>
  #haeusle {
    font-family: 'Playball', 'sans-serif' !important;
    font-weight: bold;
    font-size: 30px;
  }
  .haeusle {
    font-family: 'Playball';
  }

.home{
    position: absolute;
  }
  .fixed{
    position: fixed;
  }
  .z{
    z-index: 2;
    position: relative;
  }
</style>
