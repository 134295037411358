import { render, staticRenderFns } from "./GroupElement.vue?vue&type=template&id=1026aa82&scoped=true"
import script from "./GroupElement.vue?vue&type=script&lang=js"
export * from "./GroupElement.vue?vue&type=script&lang=js"
import style0 from "./GroupElement.vue?vue&type=style&index=0&id=1026aa82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1026aa82",
  null
  
)

export default component.exports