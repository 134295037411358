<template>
  <div>
    <v-container class="transparent">
      <v-layout align-content-center justify-center>
        <v-row justify="center">
          <v-col cols="11" lg="10">
            <v-card class="pa-4" >
              <br>
              <v-img src="../images/welcomeHome.jpeg" max-height="500" class="mt-4 pa-4" aspect-ratio="1.7"></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-row justify="center">
        <v-col cols="12" md="11" lg="10"> 
          <v-card class="pa-3 my-6" v-for="category in groups" :key="category.name">
            <div v-bind:id="category.name"></div>
            <groupElement class="mb-7" v-for="(group, index) in category.elements" :group="group" :key="index" :index="index"></groupElement>
          </v-card>
        </v-col>
      </v-row>
      <h1 ></h1>      
    <br>
    <br>
    </v-container>
  </div>
</template>

<script>  
import groupElement from '../components/GroupElement';
export default {
  name: 'Home',
  components: {
    groupElement
  },
  data: function(){
    return{
      groups: Object
    }
  },
  mounted: function(){
    // document.title = "LGV Haiterbach - das Häusle";
    var xhr = new XMLHttpRequest();
    var home = this;
    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        home.setGroups(xhr.responseText);
      }
    };
    xhr.open("POST", '/php/ServerOperations.php', true);
    // xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // xhr.send("getmaincar");
    xhr.send("getgroups");
  },
  methods: {
    setGroups(jsonGroups){
      var parsedGroups = JSON.parse(jsonGroups);
      var orderedGroups = Object();
      Object.keys(parsedGroups).forEach(key =>{
        var group = parsedGroups[key];
        if (orderedGroups[group.message.category] == undefined){
          orderedGroups[group.message.category] = {
            elements: Array(),
            name: group.message.category
          }
        }
        orderedGroups[group.message.category].elements.push(group);
      });
      // die Gruppen noch sortieren
      Object.keys(orderedGroups).forEach(key => {
        orderedGroups[key].elements.sort((a,b) => {
          if (a.message.day > b.message.day){
            return 1;
          }
          if (a.message.day < b.message.day){
            return -1;
          }
          if (a.message.clock > b.message.clock){
            return 1;
          }
          if (a.message.clock < b.message.clock){
            return -1;
          }
          return 0;
        });
      });
      this.groups = orderedGroups;
    },
  },
  updated: function(){      
    if(this.$route.params.category){
      var element = document.getElementById(this.$route.params.category);
      var headerOffset = 60;
      if (element){
        var elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
      } 
    }
  },
  watch: {
  }
}
</script>
