<template>
  <v-card rounded="2" color="primary lighten-5" elevation="4">
    <span id="haeusle" class="ml-4 mb-n5 accent--text " :class="messagedate.class">{{messagedate.value}}</span>
    <v-layout class="mt-5" style="position: absolute; z-index:1; transform: rotate(-5deg)" 
        align-start justify-start>
      <div class="transparent pb-2 pl-2 pr-6" >
        <v-card class="white " style="width:95%; height: 95%; opacity:0.75; position:absolute; z-index: -1; box-shadow: 2px 2px 10px black" rounded="5" ></v-card>
        <span class="ml-3 text-h4 text-sm-h5 text-md-h4 accent--text" id="haeusle" style="" >{{newsElement.message.title}}</span>
        <!-- <span class="text-h4 text-sm-h5 text-md-h4 white--text" id="haeusle" style="">{{newsElement.message.title}}</span> -->
      </div>
    </v-layout>
    <v-layout v-if="newsElement.message.cancelled" class="mt-5" style="position: absolute; z-index:2; transform: rotate(-3deg); width: 90%; left: 5%;  height: 50%; top: 20%" 
        align-center justify-center align-content-center>
      <v-layout class="white py-1 px-2 text-center" align-center justify-space-around style="opacity:0.90; width: 80%; height: 150px" rounded="10" elevation="20" >
        <span class="text-h2 text-sm-h1 text-md-h1 red--text text-center" id="haeusle">abgesagt</span>
      </v-layout>
    </v-layout>
    <v-row>
      <v-col cols="12" sm="6">
        <v-img v-if="newsElement.pictures && newsElement.pictures[0] != ''" :src="newsElement.pictures[0]" class="mt-0" aspect-ratio="1" contain></v-img>
        <v-card v-else height="100" class="transparent" flat></v-card>
      </v-col>
      <v-col class="mt-5" cols="12" sm="6">
        <h4>{{newsElement.message.subtitle}}</h4>
        <h4 v-if="messagetime!='00:00 Uhr'">{{messagetime}}</h4>
        <!-- <v-textarea flat full-width readonly no-resize class="text--h3" v-model="newsElement.message.content" auto-grow ></v-textarea> -->
        <br>
        <div v-for="part in messageparts" :key="part">
          <span>{{part}}</span>
          <br>
        </div>
        <br>
        <!-- LINK -->
        <div v-if="newsElement.message.link && newsElement.message.link!=''">
          <a v-if="newsElement.message.link.substring(0,1) != '/'" :href="newsElement.message.link" target="_blank">
            <h3 v-if="newsElement.message.linktext" class="mb-0 ml-3 haeusle accent--text">
              <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
              <span class="ml-2">{{newsElement.message.linktext}}</span>
            </h3>
            <h3 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
              <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
              <span class="ml-2">{{newsElement.message.link}}</span>
            </h3>
          </a>
          <router-link v-if="newsElement.message.link.substring(0,1) == '/'" :to="newsElement.message.link">
            <h3 v-if="newsElement.message.linktext" class="mb-0 ml-3 haeusle accent--text">
              <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
              <span class="ml-2">{{newsElement.message.linktext}}</span>
            </h3>
            <h3 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
              <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
              <span class="ml-2">{{newsElement.message.link}}</span>
            </h3>
          </router-link>
        </div>
        <!-- LINK -->
        <!-- <a v-if="newsElement.message.link && newsElement.message.link!=''" :href="newsElement.message.link" target="_blank">
          <h3 v-if="newsElement.message.linktext" class="mb-0 ml-3 haeusle accent--text">
            <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
            <span class="ml-2">{{newsElement.message.linktext}}</span>
          </h3>
          <h3 v-else class="mb-0 ml-2 haeusle text--accent d-inline">
            <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
            <span class="ml-2">{{newsElement.message.link}}</span>
          </h3>
        </a> -->
        <v-card v-if="newsElement.message.contact" flat color="secondary lighten-4" class="pa-2 mt-2">
          <h2 class="haeusle ml-2 mb-2" >Kontakt:</h2>
          <span class=" font-weight-bold">{{newsElement.message.contact}}</span>
          <br v-if="newsElement.message.contactmail">
          <v-icon v-if="newsElement.message.contactmail">mdi-email</v-icon>
          <a target="_blank" :href="'mailto:' + newsElement.message.contactmail" v-if="newsElement.message.contactmail" class="black--text ml-2">{{newsElement.message.contactmail}}</a>
          <br v-if="newsElement.message.contactcall">
          <v-icon v-if="newsElement.message.contactcall">mdi-phone</v-icon>
          <span v-if="newsElement.message.contactcall" class="ml-2">{{newsElement.message.contactcall}}</span>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'newsElement',
    props: {
      newsElement: Object
    },
    data (){
      return {
      }
    },
    mounted: function () {
    },
    methods:{
      compareDateOnly(date1, date2){
        var compareDate1 = date1.getFullYear() * 10000 + date1.getMonth() * 100 + date1.getDate();
        var compareDate2 = date2.getFullYear() * 10000 + date2.getMonth() * 100 + date2.getDate();
        if (compareDate1 > compareDate2){
          return 1;
        }
        if (compareDate1 < compareDate2){
          return -1;
        }
        return 0;
      }
    },
    computed:{
      messagedate: function(){
        var msgDate = this.newsElement.message.date;
        var msgEndDate = false;
        if (this.newsElement.message.enddate != undefined){
          msgEndDate = this.newsElement.message.enddate;
        }
        var today = new Date();
        // wenn das Event heute stattfindet (msgDate is heute, oder heute liegt zwischen Anfang und Ende (wenn vorhanden))
        if (this.compareDateOnly(msgDate, today) == 0 && !msgEndDate){
          return {
            value: "heute",
            class: "text-h4"
          }
        }
        if (msgEndDate){
          return {
            value: String(msgDate.getDate()).padStart(2, "0") + "." + String(msgDate.getMonth() + 1).padStart(2, "0") + "." + msgDate.getFullYear() + "  -  " + String(msgEndDate.getDate()).padStart(2, "0") + "." + String(msgEndDate.getMonth() + 1).padStart(2, "0") + "." + msgEndDate.getFullYear(),
            class: "text-h5"
          }
        }
        else{
          return {
            value: String(msgDate.getDate()).padStart(2, "0") + "." + String(msgDate.getMonth() + 1).padStart(2, "0") + "." + msgDate.getFullYear(),
            class: "text-h5"
          }
        }
      },
      messagetime (){
        return String(this.newsElement.message.date.getHours()).padStart(2, "0") + ":" + String(this.newsElement.message.date.getMinutes()).padStart(2, "0") + " Uhr";
      },
      messageparts (){
        if (this.newsElement.message.content != ''){
          return this.newsElement.message.content.split("\n");
        }
        return new Array();
      }
    }
  }
</script>

<style scoped>
  #haeusle {
    font-family: 'Playball', 'sans-serif' !important;
  }
  .haeusle {
    font-family: 'Playball';
  }
  .opac{
    opacity: 0.4;
  }
</style>