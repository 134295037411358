import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import '@fontawesome/fontawesome-free/css/all.css'
import './assets/appStyles.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false

// Vue.directive('title', {
//   inserted: (el) => {if (el.title != "") {document.title = el.title}},
//   update: (el) => {if (el.title != "") {document.title = el.title}}
// })

new Vue({
  created () {
    AOS.init()
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
